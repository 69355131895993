import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Card
} from 'reactstrap';
import { Rating } from 'react-simple-star-rating';
import Swal from 'sweetalert2';
import exitIntent from 'exit-intent';

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const domain = window.location.href.replace('www.','').split("//")[1].split("/")[0];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headline1: '',
      headline2: '',
      header: '',
      products: [],
      bottomheadline: '',
      bottomtext: '',
      footertext: '',
      exitpopheadline: '',
      exitpopbody: '',
      cbid: '',
      popupFired: false,
    };
  }
  componentDidMount() {
    // console.log(`http://localhost:3000/api/funnels/${getParameterByName('id')}`);
    fetch(`https://manage.cellphonecash.co/api/funnels/${getParameterByName('id')}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({
          cbid: data.cbid,
        });
        fetch(`https://manage.cellphonecash.co/api/categories/${data.category.id}`)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            data.headline2 = data.headline2.replace('{Current Year}', new Date().getFullYear());
            this.setState({
              headline1: data.headline1,
              headline2: data.headline2,
              header: data.header,
              products: data.products,
              bottomheadline: data.bottomheadline,
              bottomtext: data.bottomtext,
              footertext: data.footertext,
              exitpopheadline: data.exitpopheadline,
              exitpopbody: data.exitpopbody,
            });
          });
      });
  }
  renderBullets(product) {
    let bullets =[];
    for (let i=1; i<6; i++) {
      const key = `bullet${i}`;
      if (key in product) {
        if (product[key] !== '') {
          bullets.push (<li><b>✓</b> {product[key]}</li>);
        }
      }
    }
    return (<ul style={{listStyle: "none", marginLeft: -28}}> {bullets} </ul>)
  }
  renderCardOpener(index) {
    if (index === 0) {
      return {boxShadow: "0px 0px 10px gray", borderColor: 'rgba(0,116,199,1)', marginBottom: 40};
    }
    return {boxShadow: "0px 0px 10px gray", marginBottom: 40};
  }
  renderProducts(products) {
    console.log(products);
    if (products.length < 1) {
      console.log("nothing");
      return null;
    }
    let output = [];
    products.forEach((product, index) => {
      output.push (<Card style={this.renderCardOpener(index)}>
        <Row style={{marginBottom: 40}}>
          <Col md="3" className="text-center">
            <img src={product.image.url} style={{width: 146, marginTop: 40, marginBottom: 10}} />
            <Rating initialValue={product.rating/2} readonly allowFraction size={30} />
            <h2 style={{color: 'rgba(0,116,199,1)', fontWeight: "bold"}}>{product.rating}</h2>
            <p style={{fontSize: 21.5}}>Rating</p>
          </Col>
          <Col md="6">
            <h2 style={{fontWeight: "bold", marginTop: 30}}>#{index + 1}: {product.product}</h2>
            <p style={{fontSize: 21}}>
              {product.bodytext}
            </p>
            {this.renderBullets(product)}
            <a href={product.hoplink + this.state.cbid} target="_blank" style={{fontSize: 21, fontWeight: "bold", textDecoration: "none"}}>Get The Details On This Deal Now</a>
          </Col>
          <Col md="3">
            <Button
                style={{marginTop: 80, marginLeft: 40}}
                tag="a"
                color="warning"
                size="large"
                href={product.hoplink + this.state.cbid}
                target="_blank"
            >
                <h5 style={{paddingTop: 5, fontWeight: "bold", width: 140}}>VIEW DEAL</h5>
            </Button>
          </Col>
        </Row>
      </Card>)
    });
    return (<> {output} </>);
  }
  render() {
    const removeExitIntent = exitIntent({
      threshold: 1,
      maxDisplays: 2,
      eventThrottle: 100,
      onExitIntent: () => {
        if (!this.state.popupFired) {
          this.setState({popupFired: true});
          Swal.fire({
            title: '<strong>' + this.state.exitpopheadline + '</strong>',
            html: this.state.exitpopbody,
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
              'Close This Popup And Choose The Best',
            confirmButtonColor: "#ff7518",
            confirmButtonAriaLabel: 'Thumbs up, great!',
          })
        }
      }
    })
    return (
      <div style={{overflow: "hidden"}}>
        <div className="rounded px-3 px-sm-4 py-3 py-sm-5">
          <Container style={{maxWidth: 1150}}>
            <Row>
              <Col>
                <Row style={{marginBottom: 40}}>
                  <Col style={{marginLeft: 70, marginRight: 70}}>
                    <h3>{this.state.headline1}</h3>
                    <h1 style={{color: 'rgba(0,116,199,1)'}}>{this.state.headline2}</h1>
                    <p style={{fontSize: 20}} dangerouslySetInnerHTML={{__html: this.state.header}}/>
                  </Col>
                </Row>
                {this.renderProducts(this.state.products)}
                <Row style={{marginBottom: 40}}>
                  <Col style={{marginLeft: 70, marginRight: 70}}>
                    <h3 style={{fontWeight: "bold", marginBottom: 20}}>{this.state.bottomheadline}</h3>
                    <p style={{fontSize: 20}} dangerouslySetInnerHTML={{__html: this.state.bottomtext}}/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <div style={{backgroundColor: "rgba(192,192,192,1)", padding: 40, width: "120%", position: "relative", right: "10%", top: 60}}>
            <Container style={{maxWidth: 1000}} className="text-center">
              <p style={{fontSize: 16}} dangerouslySetInnerHTML={{__html: this.state.footertext}}/>
              <p style={{fontSize: 16}}>&copy;{domain}. ALL RIGHTS RESERVED.</p>
              <p style={{fontSize: 12, marginTop: 40}}>
                Please note that the owner/licensee of this website may receive a commission if you click a link on this website and make a purchase. This, however, has no bearing on the reviews and comparisons presented on this website. We will continue to do our best to assist you in finding the best solutions and products to assist you.
              </p>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
